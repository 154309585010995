/* hover */
.on-all a {
	opacity: 1;
	@include transition(.2, opacity);
}

.on-all a:hover {
	opacity: .5;
}

.on {
	opacity: 1;
	@include transition(.2, opacity);
}

.on:hover {
	opacity: .7;
}

/* pc-sp */
.switch_sp {
	display: none !important;
	@include max-screen(752px) {
		display: block !important;
		//margin: 0 auto;
	}
}

.switch_pc {
	display: block !important;
	//margin: 0 auto;
	@include max-screen(752px) {
		display: none !important;
	}
}