$contents-width: 860px;
$contents-width--sp: 620px;
//$break-point: 767px;
$break-point: 752px;

// ---------------------------------
@mixin txt_link_opacity($deco:none, $color:#000, $hover-deco:none) {
	a,
	a:link,
	a:visited {
		color: $color;
		text-decoration: $deco;
		@include transition(.2);
	}

	@include min_screen(753px) {
		a:hover {
			opacity: .5;
			text-decoration: $hover-deco;
		}
	}
}

@mixin txt_link_color($deco:none, $color:#000, $color2:#000, $hover-deco:none) {
	a,
	a:link,
	a:visited {
		color: $color;
		text-decoration: $deco;
		@include transition(.2);
	}

	@include min_screen(753px) {
		a:hover {
			color: $color2;
			text-decoration: $hover-deco;
		}
	}
}


// ---------------------------------
@mixin sp_width {
	width: percentage($contents-width--sp / 750px);
	margin: 0 auto;
}


// ---------------------------------
@mixin link_btn {
	@include txt_link_color(none, #000, #fff);

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 46px;
		border-radius: 6px;
		border: 1px solid #c8c8c8;
		box-sizing: border-box;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: .08em;
		position: relative;
		background: #fff;
		line-height: 1;

		@include ie {
			padding-top: 4px;
		}

		@include max_screen($break-point) {
			min-height: 44px;
		}

		&:after {
			content: "";
			background: none;
			width: 0;
			height: 0;
			border-left: 4px solid #fff;
			border-top: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-right: none;
			position: absolute;
			top: 50%;
			right: 17px;
			margin-top: -3px;
			transform: none;
			@include transition(.1, border-left);
		}

		&:before {
			content: "";
			width: 16px;
			height: 16px;
			background: #000;
			position: absolute;
			top: 50%;
			right: 12px;
			margin-top: -8px;
			border-radius: 50%;
			@include transition(.1, background);
		}
	}

	@include min_screen(753px) {
		a:hover {
			border-color: #469664;
			background: #469664;

			&:after {
				border-left: 4px solid #469664;
			}

			&:before {
				background: #fff;
			}
		}
	}
}

@mixin font-en-sans {
	font-family: 'metropolis';
	font-weight: 500;
}

@mixin font-en-serif {
	font-family: 'Gandhi Serif';
	font-weight: 400;
}

@mixin font-jp-sans {
	font-family: 'Noto Sans Japanese';
	font-weight: 400;
}

@mixin font-jp-serif {
	font-family: 'Noto Serif Japanese';
	font-weight: 400;
}

@mixin cf {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}

@mixin placeholderColor($color, $paddingtop:false) {
	&:placeholder-shown {
		color: $color;
		@if $paddingtop {
			padding-top: $paddingtop;
		}
	}
	&::-webkit-input-placeholder {
		color: $color;
		@if $paddingtop {
			padding-top: $paddingtop;
		}
	}
	&:-moz-placeholder {
		color: $color;
		opacity: 1;
		@if $paddingtop {
			padding-top: $paddingtop;
		}
	}
	&::-moz-placeholder {
		color: $color;
		opacity: 1;
		@if $paddingtop {
			padding-top: $paddingtop;
		}
	}
	&:-ms-input-placeholder {
		color: $color;
		@if $paddingtop {
			padding-top: $paddingtop;
		}
	}
}

@mixin transition($time:.3, $prop:all, $easing:ease-in-out) {
	-moz-transition: $prop #{$time}s $easing;
	-webkit-transition: $prop #{$time}s $easing;
	transition: $prop #{$time}s $easing;
}

@mixin min_screen($break-point) {
	@media screen and (min-width: $break-point) {
		@content;
	}
}

@mixin max_screen($break-point) {
	@media screen and (max-width: $break-point) {
		@content;
	}
}

@mixin ie() {
	@media all and (-ms-high-contrast:none) {
		@content;
	}
}