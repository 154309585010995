@import "default";
@import "var";
@import "util";

body {
	width: 100%;
	background: #fff;
	font-size: 13px;
	color: #000;
	text-align: center;
	font-feature-settings: "palt";
	@include font-jp-sans;

	img {
		max-width: 100%;
	}
}

//-----------------------------------------------
//-----------------------------------------------
//-----------------------------------------------
//-----------------------------------------------
//-----------------------------------------------
//-----------------------------------------------