@charset "UTF-8";
@font-face {
  font-family: 'Gandhi Serif';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/font/GandhiSerif-Regular.woff") format("woff"), url("/assets/font/GandhiSerif-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Gandhi Serif';
  font-style: normal;
  font-weight: 500;
  src: url("/assets/font/GandhiSerif-Bold.woff") format("woff"), url("/assets/font/GandhiSerif-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/font/Metropolis-Regular.woff") format("woff"), url("/assets/font/Metropolis-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 500;
  src: url("/assets/font/Metropolis-Medium.woff") format("woff"), url("/assets/font/Metropolis-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  src: url("/assets/font/Metropolis-SemiBold.woff") format("woff"), url("/assets/font/Metropolis-Semibold.ttf") format("truetype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/font/NotoSansCJKjp-Regular.woff") format("woff"), url("/assets/font/NotoSansCJKjp-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 500;
  src: url("/assets/font/NotoSansCJKjp-Medium.woff") format("woff"), url("/assets/font/NotoSansCJKjp-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/font/NotoSerifCJKjp-Regular.woff") format("woff"), url("/assets/font/NotoSerifCJKjp-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 500;
  src: url("/assets/font/NotoSerifCJKjp-Medium.woff") format("woff"), url("/assets/font/NotoSerifCJKjp-Medium.ttf") format("truetype"); }

/* ===============================================

	RESET
  
=============================================== */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

h1, h2, h3, h4, h5 {
  line-height: 1;
  font-size: 100%;
  font-weight: normal; }

img {
  border: none;
  vertical-align: bottom; }

ul, ol, li, dl, dt, dd {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-weight: normal; }

caption, th, td {
  text-align: left; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, textarea, select {
  vertical-align: middle;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }
  @media screen and (max-width: 767px) {
    input, textarea, select {
      font-size: 16px; } }

address {
  font-style: normal; }

/* ===============================================

	FONT SIZE
  
=============================================== */
body {
  font-size: 13px;
  line-height: 1.5;
  -webkit-text-size-adjust: none; }

select, input, textarea, button {
  font-size: 13px;
  -webkit-text-size-adjust: none; }
  @media screen and (max-width: 767px) {
    select, input, textarea, button {
      font-size: 16px; } }

table {
  font-size: inherit;
  font: 100%; }

sup {
  font-size: 77%;
  vertical-align: top; }

/* ===============================================

	FONT FAMILY
  
=============================================== */
body {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-rendering: optimizeLegibility; }

/* hover */
.on-all a {
  opacity: 1;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out; }

.on-all a:hover {
  opacity: .5; }

.on {
  opacity: 1;
  -moz-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out; }

.on:hover {
  opacity: .7; }

/* pc-sp */
.switch_sp {
  display: none !important; }
  @media screen and (max-width: 752px) {
    .switch_sp {
      display: block !important; } }

.switch_pc {
  display: block !important; }
  @media screen and (max-width: 752px) {
    .switch_pc {
      display: none !important; } }

body {
  width: 100%;
  background: #fff;
  font-size: 13px;
  color: #000;
  text-align: center;
  font-feature-settings: "palt";
  font-family: 'Noto Sans Japanese';
  font-weight: 400; }
  body img {
    max-width: 100%; }
