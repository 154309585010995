@import "var";

//-----------------------------------------------
.contents-body {
	width: 100%;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	text-align: left;
	max-width: 1780 + 200px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 100px;
	box-sizing: border-box;

	//@include max_screen(1300px) {
	@include max_screen(945px) {
		padding-left: 40px;
		padding-right: 40px;
	}

	@include max_screen($break-point) {
		display: block;
		padding: 0;
	}

	.main-column {
		width: percentage(730 / 1000);
		order: 2;

		@include max_screen($break-point) {
			width: percentage(650 / 750);
			margin: 0 auto;
		}
	}

	.sub-column {
		width: percentage(190 / 1000);
		order: 1;

		@include max_screen($break-point) {
			width: 100%;
		}
	}
}


//-----------------------------------------------
.sub-column {
	.block {
		width: 100%;
		padding-bottom: 21px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 24px;

		&:last-of-type {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		.ttl {
			@include font-en-serif;
			color: #999;
			letter-spacing: .1em;
			line-height: 1;
			margin-bottom: 17px;
		}

		.list {
			@include font-en-serif;
			@include txt_link_opacity;
			letter-spacing: .1em;
			line-height: percentage(15 / 13);

			li {
				margin-bottom: 16px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	@include max_screen($break-point) {
		.archive-btn__wrap {
			width: 100%;
			border: 1px solid #000;
			border-width: 2px 0;
		}

		.archive-btn {
			width: 100%;
			border-bottom: 1px solid #999;
			position: relative;

			&:last-child {
				border: none;
			}

			.display {
				width: 100%;
				height: 45px;
				@include font-en-serif;
				font-size: 14px;
				letter-spacing: .1em;
				line-height: 1;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			select {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
			}
		}
	}
}


//-----------------------------------------------
.main-column {
	.entry-list {

		&__item {
			width: 100%;
			margin-bottom: 55px;
			border-bottom: 1px solid #ccc;
			padding-bottom: 90px;

			&:last-child {
				margin-bottom: 0;
				border-bottom: none;
			}

			@include max_screen($break-point) {
				margin-bottom: 35px;
				padding-bottom: 35px;
			}
		}

	}

	.entry {
		width: 100%;

		&.single {
			padding-bottom: 90px;

			@include max_screen($break-point) {
				padding-bottom: 45px;

				.entry__meta {
					margin-bottom: 26px;
				}
			}
		}

		&__ttl {
			font-family: 'Gandhi Serif', 'Noto Serif Japanese', Serif;
			font-size: 25px;
			letter-spacing: .1em;
			line-height: percentage(32 / 25);
			@include txt_link_opacity;
			margin-bottom: 14px;

			@include max_screen($break-point) {
				font-size: 22px;
				letter-spacing: .05em;
				line-height: percentage(58 / 44);
				margin-bottom: 7px;
			}
		}

		&__meta {
			@include font-en-serif;
			color: #999;
			font-size: 13px;
			letter-spacing: .1em;
			@include txt_link_opacity(none, #999);
			margin-bottom: 52px;

			@include max_screen($break-point) {
				margin-bottom: 14px;
				font-size: 12px;
				letter-spacing: .05em;
			}

			.post-categories {
				display: inline-block;

				li {
					display: inline-block;

					&:after {
						content: ", ";
					}

					&:last-child {
						&:after {
							display: none;
						}
					}
				}

				@include max_screen($break-point) {
					display: block;
				}
			}
		}

		&__thumb {
			width: 100%;
			margin-bottom: 20px;
		}

		&__excerpt {
			font-size: 13px;
			line-height: percentage(46 / 26);
			margin-bottom: 14px;
		}

		&__more-btn {
			@include txt_link_color;
			@include font-en-sans;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: .1em;
			line-height: 1;
		}

		&__body {
			line-height: percentage(25 / 13);
			letter-spacing: .16em;
			@include txt_link_color(underline);

			img {
				height: auto;
			}

			.aligncenter {
				display: block;
				text-align: center;
				margin: 0 auto;
			}

			.alignleft {
				display: block;
				text-align: left;
				margin: 0 auto 0 0;
			}

			.alignright {
				display: block;
				text-align: right;
				margin: 0 0 0 auto;
			}

			@include max_screen($break-point) {
				font-size: 13px;
				line-height: percentage(46 / 26);
				letter-spacing: .1em;
			}
		}
	}

	.more-btn {
		@include font-en-sans;
		font-weight: 600;
		letter-spacing: .1em;
		line-height: 1;
		font-size: 12px;
		@include txt_link_opacity;
		padding-top: 25px;
		border-top: 1px solid #ccc;
		width: 100%;
		text-align: center;

		a {
			display: inline-block;
			padding-right: 22px;
			position: relative;

			&:after {
				content: "";
				width: 13px;
				height: 13px;
				background: url("/assets/img/more_btn.png") no-repeat;
				position: absolute;
				top: 50%;
				right: 0;
				margin-top: -6px;
			}
		}

		@include max_screen($break-point) {
			font-size: 15px;
			padding: 40px 0;

			a {
				padding-right: 23px;

				&:after {
					width: 15px;
					height: 15px;
					background: url("/assets/img/more_btn-sp.png") no-repeat;
					background-size: 15px 15px;
					margin-top: -10px;
				}
			}
		}
	}

	.pager {
		padding-top: 26px;
		@include font-en-sans;
		font-weight: 600;
		@include txt_link_opacity;
		letter-spacing: .2em;
		line-height: 1;
		display: flex;
		justify-content: center;
		font-size: 12px;
		border-top: 1px solid #ccc;

		@include min_screen($break-point + 1px) {
			&__prev {
				&:after {
					content: "";
					border-right: 1px solid #000;
					padding-right: 14px;
					margin-right: 15px;
				}
			}

			&__next {
				&:before {
					content: "";
					border-left: 1px solid #000;
					padding-left: 15px;
					margin-left: 14px;
				}
			}
		}

		@include max_screen($break-point) {
			padding: 20px 0;
			box-sizing: border-box;
			font-size: 15px;
			letter-spacing: .1em;
			position: relative;
			display: block;
			overflow: hidden;
			@include txt_link_color(none, #979797);

			a {
				width: 100%;
				height: 53px;
				display: flex;
				align-items: center;
				box-sizing: border-box;
			}

			&__prev {
				float: left;

				a {
					padding-left: 18px;
					background: url("/assets/img/pager_left_arw.png") no-repeat left 16px;
					background-size: auto 17px;
				}
			}

			&__next {
				float: right;

				a {
					padding-right: 18px;
					position: relative;
					background: url("/assets/img/pager_right_arw.png") no-repeat right 16px;
					background-size: auto 17px;
				}
			}

			&__list {
				width: 70px;
				position: absolute;
				box-sizing: border-box;
				top: 20px;
				left: 50%;
				margin-left: -35px;
				text-align: center;

				a {
					justify-content: center;
				}
			}
		}
	}
}


//-----------------------------------------------
//-----------------------------------------------
//-----------------------------------------------